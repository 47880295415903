// React
import { getColorStyle } from '@utmb/utils/colors';
import { ReactElement } from 'react';
// Styles
import styles from './race-swipe-cards.module.scss';

const DotIcon = (props: { color?: string }): ReactElement => {
    return (
        <span
            aria-hidden="true"
            className={styles.dot}
            style={{
                ...(props.color && {
                    backgroundColor: getColorStyle(props.color),
                }),
            }}
        />
    );
};

export default DotIcon;
