import { UnitEnum } from '@utmb/types/unit-enum';

export const formatUnit = (
    unit: UnitEnum,
    type: 'distance' | 'elevation' | 'elevationGain' | 'elevationLoss',
    value: number,
    originUnit: UnitEnum = UnitEnum.METRIC,
    noSuffix = false
): string => {
    value = originUnit === UnitEnum.METRIC ? value : type === 'distance' ? value / 0.621371 : value / 3.28084;
    let result = '';
    switch (type) {
        case 'distance':
            if (unit === UnitEnum.METRIC) result = `${Math.floor(value * 10) / 10}`;
            else result = `${Math.floor(value * 6.21371) / 10}`;
            break;
        case 'elevation':
        case 'elevationGain':
        case 'elevationLoss':
            if (unit === UnitEnum.METRIC) result = `${Math.floor(value)}`;
            else result = `${Math.floor(value * 3.28084)}`;
            break;
    }

    if (noSuffix) return result;

    switch (type) {
        case 'distance':
            if (unit === UnitEnum.METRIC) result = `${result} KM`;
            else result = `${result} Miles`;
            break;
        case 'elevation':
            if (unit === UnitEnum.METRIC) result = `${result} M`;
            else result = `${result} ft`;
            break;
        case 'elevationGain':
            if (unit === UnitEnum.METRIC) result = `${result} M+`;
            else result = `${result} ft+`;
            break;
        case 'elevationLoss':
            if (unit === UnitEnum.METRIC) result = `${result} M-`;
            else result = `${result} ft-`;
            break;
    }

    return result;
};
