import React from 'react';
import { Font } from '../text/font';

import styles from './running-stone.module.scss';
import classNames from 'classnames';
import { ColorTypesEnum } from '@utmb/types/colors';
import { default as NextImage } from 'next/image';
import { getColorStyle } from '@utmb/utils/colors';
import { rem } from '@utmb/utils/font';

const cx = classNames.bind(styles);

interface RunningStonesProps {
    stones: number | string;
    width?: number;
    height?: number;
}

const RunningStones = (props: RunningStonesProps) => {
    const { stones, height=50, width=50 } = props;

    const sizeWidth = Math.round(width * 0.4);

    const stonesLength = String(stones).length;

    const stonesQuantityCSS = cx(styles.stones_quantity, {
        two_digits: stonesLength === 2,
        three_digits: stonesLength >= 3,
    });

    return (
        <div className={styles.runner_stones_container}>
            <div className={styles.content} style={{ width: rem(width), height: rem(height) }}>
                <Font.OxaniumRegular
                    desktop={sizeWidth.toString()}
                    mobile={sizeWidth.toString()}
                    color={getColorStyle(ColorTypesEnum.WS_SECONDARY)}
                    className={stonesQuantityCSS}
                >
                    {stones}
                </Font.OxaniumRegular>
            </div>
        </div>
    );
};

export default RunningStones;
