import RunningStones from '@utmb/design-system/running-stone/RunningStone';
import { useCloudinary } from '@utmb/design-system/use-cloudinary/UseCloudinary';
import { WorldSeriesCategoryEnum } from '@utmb/types/enum/WorldSeriesCategoryEnum';
import { getRaceCategoryIcon } from '@utmb/utils/race-categories-icons';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { StatView } from '@utmb/types/index';
import { usePreference } from '@utmb/contexts/PreferenceContext';
import { formatUnit } from '@utmb/utils/unit';
import { UnitEnum } from '@utmb/types/unit-enum';
import { Icon } from '@utmb/design-system/icons/Icon';
import { IconsEnum } from '@utmb/types/icons';
import DirectEntry from '@utmb/design-system/direct-entry/DirectEntry';

export const KeyFigure = ({ name, value, postfix, isDark = false }: StatView & { isDark?: boolean }): ReactElement => {
    const { getImage } = useCloudinary();
    const { t } = useTranslation();

    const {
        preferences: { preferredUnit },
    } = usePreference();

    if (name === 'categoryWorldSeries') {
        return (
            <img src={getImage(getRaceCategoryIcon(value as WorldSeriesCategoryEnum, isDark)?.publicId)} alt={value.toString()} style={{ maxHeight: '5rem' }} />
        );
    } else if (name === 'runningStones') {
        return <RunningStones stones={value} />;
    } else if (name === 'directEntry') {
        return <DirectEntry publicId={`${value}`} />;
    } else if (name === 'startPlace' || name === 'startPlaceAndTime') {
        return (
            <span className="pin-icon">
                <Icon variant={IconsEnum.PIN} color={isDark ? '#ffffff' : '#41505c'} />
                {value}
            </span>
        );
    } else if (name === 'maxTime') {
        const [hours, minutes] = value.toString().split(':');
        const val = +minutes ? t('races.keyfigures.maxTimeFormat', { hours, minutes }) : t('races.keyfigures.maxTimeFormatHourOnly', { hours });
        return <>{val}</>;
    } else if (name === 'distance') {
        return <>{formatUnit(preferredUnit, 'distance', Number(value), postfix === 'miles' ? UnitEnum.IMPERIAL : UnitEnum.METRIC)}</>;
    } else if (name === 'elevationGain') {
        return <>{formatUnit(preferredUnit, 'elevationGain', Number(value), postfix === 'feet' ? UnitEnum.IMPERIAL : UnitEnum.METRIC)}</>;
    } else if (name === 'elevationLoss') {
        return <>{formatUnit(preferredUnit, 'elevationLoss', Number(value), postfix === 'feet' ? UnitEnum.IMPERIAL : UnitEnum.METRIC)}</>;
    } else {
        return (
            <>
                {value}
                {postfix && postfix}
            </>
        );
    }
};
