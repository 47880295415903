// React
import { ReactElement } from 'react';
// Utils
import { useTranslation } from 'next-i18next';
// Modules
import classNames from 'classnames/bind';
// Interfaces
import { StatView } from '@utmb/types/index';
// Design System
import { Font } from '@utmb/design-system/text/font';
// Styles
import styles from './world-series-stat.module.scss';
import { KeyFigure } from '@utmb/features/key-figures/KeyFigure';

const cx = classNames.bind(styles);

interface StatProps extends StatView {
    raceTheme?: string;
    labelClassName?: string;
    valueClassName?: string;
    rsSize?: number;
}

const WorldSeriesStat = (props: StatProps): ReactElement => {
    const { name, labelClassName } = props;

    const { t } = useTranslation();

    const wssStatValueCSS = cx(styles.wss_value, {});

    return (
        <div className={styles.wss_stat}>
            <Font.OxaniumRegular mobile="12" desktop="14" className={[styles.wss_label, labelClassName].filter(Boolean).join(' ')}>
                {t(`races.keyfigures.${name}`)}
            </Font.OxaniumRegular>
            <Font.OxaniumBold mobile="18" desktop="20" className={wssStatValueCSS}>
                <KeyFigure {...props} />
            </Font.OxaniumBold>
        </div>
    );
};

export default WorldSeriesStat;
