// React
import { ReactElement, useEffect, useState } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Utils
import { getColorStyle, LightenDarkenColor } from '@utmb/utils/colors';
// DESIGN SYSTEM
import { Slides, SlidesItem, SlideProps } from '@utmb/design-system/slides/Slides';
import WorldSeriesStat from '@utmb/features/world-series/race-list/WorldSeriesStat';
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import DotIcon from '@utmb/features/races/swipe-cards/DotIcon';
// INTERFACES
import { RaceItemView, SliceRaceListView } from '@utmb/types/index';
import { Slice } from '@utmb/types/slice-handler';
// CSS
import styles from './race-swipe-cards.module.scss';
import { ColorTypesEnum } from '@utmb/types/colors';
import { RaceRegistrationStatusEnum } from '@utmb/types/enum/RaceRegistrationStatusEnum';
import { useGlobal } from '@utmb/contexts/GlobalContext';

export const RaceSwipeCards = (props: Slice<SliceRaceListView>): ReactElement | null => {
    const { id, races, isDark } = props;
    const raceLinkSlug = '/races/';
    const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
    const [showSliderDotsCSS, setShowSliderDotsCSS] = useState(styles.dots);
    const {
        global: {
            event: { primaryColorIsDark },
        },
    } = useGlobal();

    const { t } = useTranslation();

    const rootCSS = [styles.root].filter(Boolean).join(' ');

    const slideProps: SlideProps = {
        className: styles.container,
        carouselOptions: {
            containScroll: 'trimSnaps',
            align: 'center',
            draggable: true,
            loop: false,
        },
        dotsClassName: showSliderDotsCSS,
    };

    const handleResize = () => {
        const containerWidth = races.length * (parseInt(styles.slideItemMaxWidth) + parseInt(styles.slideItemMarginRight));
        if (document && document.body.clientWidth < containerWidth) {
            setContainerWidth(undefined);
            setShowSliderDotsCSS([styles.dots, styles.showdots].join(' '));
        } else {
            setContainerWidth(containerWidth);
            setShowSliderDotsCSS(styles.dots);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
        handleResize();
    }, []);

    if (!races || !races.length) return null;

    return (
        <div id={id} className={rootCSS}>
            <Slides {...slideProps} pagination={true} isDarkPagination={isDark} containerWidth={containerWidth}>
                {() => (
                    <>
                        {races.map((race: RaceItemView, index: number) => {
                            const colorToUse = race.raceThemeIsDark ? race.raceTheme : LightenDarkenColor(race.raceTheme, -20);
                            return (
                                <SlidesItem key={index} className={styles.item}>
                                    <div className={styles.image}>
                                        <Image layout="fill" objectFit="cover" image={race.media} maxWidth={500} />
                                        {race.raceStatus && (
                                            <div className={styles.status}>
                                                <Font.OxaniumBold mobile="17" desktop="19">
                                                    {race.raceStatus.open ? (
                                                        <>
                                                            <DotIcon color="green" /> {t(`racesList.registration_open`)}
                                                        </>
                                                    ) : race.raceStatus.status === RaceRegistrationStatusEnum.AVAILABLE_SOON ? (
                                                        <>
                                                            <DotIcon color="#fd4b01" /> {t(`racesList.available_soon`)}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <DotIcon color="red" /> {t(`racesList.${race.raceStatus.status}`)}
                                                        </>
                                                    )}
                                                </Font.OxaniumBold>
                                            </div>
                                        )}

                                        {race.startDate && (
                                            <div
                                                className={styles.date}
                                                style={{
                                                    backgroundColor: `${race.raceTheme}`,
                                                    color: race.raceThemeIsDark ? getColorStyle(ColorTypesEnum.WHITE) : getColorStyle(ColorTypesEnum.DEFAULT),
                                                }}
                                            >
                                                <Font.OxaniumRegular mobile="12" desktop="14" as="span">
                                                    {race.startDate}
                                                </Font.OxaniumRegular>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.content} style={{ borderColor: `${race.raceTheme}` }}>
                                        <div className={styles.header}>
                                            {race.logo ? (
                                                <Image
                                                    layout="fixed"
                                                    objectFit="contain"
                                                    width={172}
                                                    height={50}
                                                    image={race.logo}
                                                    alt={race.name}
                                                    className={styles.logo}
                                                />
                                            ) : (
                                                <Font.OxaniumBold mobile="20" desktop="22" className="lh-25" as="span">
                                                    <span
                                                        style={{
                                                            ...(colorToUse && {
                                                                color: colorToUse,
                                                            }),
                                                        }}
                                                    >
                                                        {race.name}
                                                    </span>
                                                </Font.OxaniumBold>
                                            )}
                                            {/*<WorldSeriesStat
                                                name="startPlace"
                                                value={race.startLocation}
                                                labelClassName={styles.statLabel}
                                                valueClassName={styles.statValue}
                                            />*/}
                                        </div>
                                        <div className={styles.cols}>
                                            {race.details.statsUp.slice(0, 2).map((stat) => (
                                                <WorldSeriesStat
                                                    {...stat}
                                                    labelClassName={styles.statLabel}
                                                    valueClassName={styles.statValue}
                                                    key={stat.name}
                                                />
                                            ))}
                                            {race.details.statsDown.slice(0, 2).map((stat) => (
                                                <WorldSeriesStat
                                                    {...stat}
                                                    labelClassName={styles.statLabel}
                                                    valueClassName={styles.statValue}
                                                    key={stat.name}
                                                />
                                            ))}
                                        </div>
                                        <div className={styles.footer}>
                                            <Link.Button
                                                label={t('racesList.labelLinkDetail')}
                                                href={`${raceLinkSlug}${race.slug}`}
                                                color={getColorStyle(primaryColorIsDark ? ColorTypesEnum.WHITE : ColorTypesEnum.DEFAULT)}
                                                bgcolor={getColorStyle(ColorTypesEnum.PRIMARY)}
                                                className={styles.btn}
                                                underline={false}
                                            />
                                        </div>
                                    </div>
                                </SlidesItem>
                            );
                        })}
                    </>
                )}
            </Slides>
        </div>
    );
};
