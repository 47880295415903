import React from 'react';

import styles from './direct-entry.module.scss';
import classNames from 'classnames';
import { useCloudinary } from '../use-cloudinary/UseCloudinary';
import { CloudinaryTransformEnum } from '@utmb/types/cloudinary-transform.enum';

const cx = classNames.bind(styles);

interface DirectEntryProps {
    publicId: string;
    width?: number;
    height?: number;
}

const DirectEntry = (props: DirectEntryProps) => {
    const { publicId, height=50, width=50 } = props;
    const { getImage } = useCloudinary();

    return (
        <>
            {publicId && <div className={styles.direct_entry_container}>
                <img src={getImage(`Common/categories/${publicId}`, { addOptions: [CloudinaryTransformEnum.TRIM] })} style={{ height, width }} />
            </div>}
        </>
    );
};

export default DirectEntry;
